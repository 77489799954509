
export default {
    // components: {
    //   Swiper
    // },
    props: [],
    name: 'service',
    data() {
        return {
            certList: [{
                key: 1,
                url: require("../../assets/图片7.png")
            }, {
                key: 2,
                url: require("../../assets/图片2.png")
            }, {
                key: 3,
                url: require("../../assets/图片5.png")
            }, {
                key: 4,
                url: require("../../assets/图片3.png")
            }, {
                key: 5,
                url: require("../../assets/图片6.png")
            }],
            showCertList: [],
            showIndex: 0,

        }
    },
    created() {
    },
    mounted() {
        this.showIndex = 0
        this.showCertList = this.certList.slice(0, 3)
    },
    methods: {
        choosePro(obj) {
            this.productActive = obj.key
            this.activeImg = obj.key
        },
        showNext() {
            let key = this.showCertList[this.showCertList.length - 1].key
            let showKey = key + 1 > this.certList.length ? 1 : key + 1
            let last = this.certList.find(c => c.key == showKey)
            let showList = this.showCertList.slice(1, 3)
            this.showCertList = [...showList, last]

        },
        showPre() {
            let key = this.showCertList[0].key
            let showKey = key - 1 > 0 ? key - 1 : this.certList[this.certList.length - 1].key
            let last = this.certList.find(c => c.key == showKey)
            let showList = this.showCertList.slice(0, 2)
            this.showCertList = [last, ...showList]

        }

    }


}
